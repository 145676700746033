import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const amenityItemComputed = {
    ...mapState('amenityitem', {
        amenityitem:(state) => state.amenity_items,
    }),
    ...mapGetters('amenityitem', ['getAmenityitem'])
}

// export property actions
export const amenityItemMethods = mapActions('amenityitem', ['allAmenityItem','createAmenityItem', 'updateAmenityItem', 'deleteAmenityItem',
])
